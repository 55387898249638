import "./bootstrap";
import "flowbite";
import "../css/app.css";
import { DataTable } from "./data-tables";
import { CountUp } from "./countup";
import { suneditor, suneditor_plugins } from "./editor";
import { flatpickr } from "./flatpickr";
import { IMask } from "./input-masking";
import { TomSelect } from "./tom-select";

// Assign modules to the window object for global access
window.DataTable = DataTable;
window.CountUp = CountUp;
window.suneditor = suneditor;
window.suneditor_plugins = suneditor_plugins;
window.flatpickr = flatpickr;
window.IMask = IMask;
window.TomSelect = TomSelect;

import.meta.glob(["../images/**", "../fonts/**"]);
